import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
// @ts-ignore
import 'react-calendar/dist/Calendar.css';
import './DateAndTimeOption.scss';
// @ts-ignore
import Calendar from 'react-calendar';
import ComboCheckboxQuestion from './ComboCheckboxQuestion';
import { JsaRequestedAppModal } from './JsaRequestedAppModal';
import { ErrorRequestModal } from './ErrorRequestModal';
import jsaAppointmentService from '../../services/unauthenticatedJsaService';
import { Loading } from './Loading';

const jsaFormInitialForm = {
    preferredDate: '',
    preferredTimeOfDay: []
};

interface Props {
    memberId: string;
}

export const UnauthenticatedJSAForm = ({ memberId }: Props) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [requestForm, setRequestForm] = useState(jsaFormInitialForm);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [subscriptions, setSubscriptions] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            subscriptions.forEach((sub: any) => sub.unsubscribe());
        };
    }, []);

    const handleOnChange = (value: any, name: string) => {
        setRequestForm({
            ...requestForm,
            [name]: value,
        });
    };

    const validateFormValues = () => {
        return Object.values(requestForm).every((value) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value?.toString().trim() != '';
        });
    };

    const onSubmitForm = () => {
        const requestData = {
            memberId: memberId,
            status: 1,
            preferredDate: requestForm.preferredDate,
            preferredTimeOfDay: requestForm.preferredTimeOfDay
        };
        setLoading(true)
        const requestSubscription = jsaAppointmentService.createJsaRequest$(requestData).subscribe({
            next: () => {
                setShowSuccessModal(true);
                setLoading(false)
            },
            error: (e) => {
                setShowErrorModal(true);
                setLoading(false)
            },
        });
        setSubscriptions([...subscriptions, requestSubscription]);
    };

    return (
        <>
            <div style={{ display: 'block' }} className="calender-container">
                <p style={{ fontSize: '2rem', fontWeight: 500 }}>Select Your Preferred Date</p>
                <Calendar
                    calendarType="US"
                    minDate={tomorrow}
                    onChange={(e: any) => handleOnChange(e, 'preferredDate')}
                    value={requestForm.preferredDate}
                />
            </div>
            <br />
            <div style={{ display: 'block' }}>
                <p style={{ fontSize: '2rem', fontWeight: 500 }}>
                    Select Your Preferred Time of Day
                </p>
                <p>Find a time range that works for you</p>
                <ComboCheckboxQuestion
                    isColumn={true}
                    onChange={(e: any) => {
                        if (requestForm.preferredTimeOfDay.includes(e as never)) {
                            const index = requestForm.preferredTimeOfDay.indexOf(e as never);
                            const newValues = [...requestForm.preferredTimeOfDay];
                            newValues.splice(index, 1);
                            handleOnChange(newValues, 'preferredTimeOfDay');
                        } else {
                            handleOnChange(
                                [...requestForm.preferredTimeOfDay, e],
                                'preferredTimeOfDay'
                            );
                        }
                    }}
                    value={requestForm.preferredTimeOfDay}
                    question="Select your preferred time of day"
                    options={[
                        {
                            label: 'Morning/ 8AM - 12PM',
                            value: 1,
                        },
                        {
                            label: 'Afternoon/ 12PM - 4PM',
                            value: 2,
                        }
                    ]}
                />
            </div>
            <div className="text-center mt-5">
                <Button
                    className="button"
                    onClick={onSubmitForm}
                    variant="primary"
                    size="lg"
                    disabled={!validateFormValues()}
                >
                    Submit
                </Button>
            </div>
            <JsaRequestedAppModal
                show={showSuccessModal}
                onHideModal={() => {
                    setRequestForm(jsaFormInitialForm);
                    window.location.href = "https://members.alignmenthealthplan.com/";
                    setShowSuccessModal(false);
                }}
            />        
            <ErrorRequestModal
                showErrorModal={showErrorModal}
                handleCloseErrorModal={() => setShowErrorModal(false)}
                text={`We're sorry. There was an issue with booking your appointment. Please try again
                later.`}
            />
           {loading &&  <Loading/>}
        </>
    );
};

