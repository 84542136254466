import React from 'react';
import { Container } from 'reactstrap';

const Footer = (props) => {
    return (
        <div className="footer" role="contentinfo">
            <Container>
                <p>
                    &#169; {new Date().getFullYear()} Alignment Health Plan. All Rights Reserved.{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.alignmenthealthplan.com/about-us/terms-of-use`}
                    >
                        Privacy Policy &amp; Terms of Use
                    </a>
                </p>
            </Container>
        </div>
    );
};

export default Footer;

export { Footer };

Footer.propTypes = {};
