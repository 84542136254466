import React from 'react';
import { handleEnterKeyToClick } from './accessibilityHelpers';
import {  Col, Row } from 'reactstrap';
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './VirtualCare.module.scss';

interface Props {
    show: boolean;
    onHideModal: () => void;
}
export const JsaRequestedAppModal = ({ show, onHideModal }: Props) => {
    return (
        <Modal centered show={show} onHide={onHideModal}>
            <div className={styles['success-alert']}>
                <div className={styles['calendar-icon-container']}>
                    <FontAwesomeIcon
                        className={styles['calendar-icon']}
                        style={{ left: '0%', display: 'inline-block' }}
                        aria-label="Success"
                        size="3x"
                        icon={faCheckCircle}
                    />
                    <h3 style={{ display: 'inline-block' }}>Request Submitted</h3>
                </div>
                <div className="text-center">
                    <p>
                        Thank you! Your request for your no-cost annual checkup has been submitted.
                    </p>
                    <p>
                        An Associate from our team will call you within 3-4 business days to
                        schedule your appointment.
                    </p>
                    <p>
                        If you have not received a call or have questions, our scheduling team is
                        ready to assist you, so call 844-215-2443 (TTY: 711), Mon - Fri, 8 AM - 6 PM
                        PST (excluding holidays).
                    </p>
                </div>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <button
                                className={styles['modal-btn']}
                                onClick={onHideModal}
                                onKeyUp={handleEnterKeyToClick}
                                tabIndex={0}
                            >
                                OK
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
