import React from 'react'
import { Button, Modal } from 'react-bootstrap';

interface Props {
    showErrorModal: boolean;
    handleCloseErrorModal: () => void;
    text: string;
}

export const ErrorRequestModal = ({showErrorModal, handleCloseErrorModal, text}:Props) => {
  return (
    <Modal centered show={showErrorModal} onHide={handleCloseErrorModal}>
    <Modal.Body>
        {text}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseErrorModal}>
            Close
        </Button>
    </Modal.Footer>
</Modal>
  )
}
