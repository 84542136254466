import React from 'react';

import { Container } from 'reactstrap';

const UnauthenticatedHeader = () => {
    return (
        <Container
            className="header d-lg-flex"
            role="complementary"
            aria-label="Most top header"
        >
            <div className="d-flex flex-grow-0 align-items-center">
            <img src={require('./logo.jpg')} height={40} alt="Logo" style={{marginTop: 10, marginBottom: 10}}/>
            </div>
        </Container>
    );
};

export { UnauthenticatedHeader };
